module.exports = {
  selectLanguage: "言語を選択",
  "index.textBy": `文: {author}`,
  "index.handwritingBy": `手書き: {interpreterName}({interpreterArea})`,
  "artist.teresa.name": `テレサ・フェルドマン`,
  "artist.teresa.role": `アーティスト / デザイナー`,
  "artist.uchida.name": `内田聖良`,
  "artist.uchida.role": `インターネット・ベンダー`,
  "statement.description": `「家族」や「ケア」について再考したアーティストの内田聖良（日本）と、アーティスト／デザイナーのテレサ・フェルドマン（オランダ）のオンライン・アーティスト・イン・レジデンスプログラム『リミナル・スペース』（2021年）を起点に、両者と関わりのあった個人が寄稿した活動報告文を、クラウドソーシングで世界中の書き手にリライトを依頼しました。パンデミックで表面化した現在進行形のパラダイムシフト。ここでは、クラウド上で世界と家族を支える人の声がリミナルに混在しています。`,
  "menu.about": "概要",
  "menu.links": "リンク集",
};
