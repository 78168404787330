import React from "react";
import { graphql } from "gatsby";
import Frontpage from "../components/Frontpage";
import Layout from "../layouts/ja"

// markup
const IndexPage = (props) => {
  let data = props.data.allMarkdownRemark.edges;
  return (
    <Layout location={props.location}>
      <Frontpage lang={"ja"} data={data} />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      sort: { fields: fields___slug, order: ASC }
      filter: { frontmatter: { lang: { eq: "ja" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            author
            authorShort
            interpreter {
              area
              name
              flag
              questions {
                answer
                question
              }
            }
            pages {
              image {
                childImageSharp {
                  fluid(maxWidth: 512, quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            template
            title
          }
        }
      }
    }
  }
`;
